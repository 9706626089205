import React from "react";
import { Navbar, Nav, Container, Row, Col, Card } from 'react-bootstrap';
import {useI18nSection} from "../../utils/languageUtils";

export const OutMore = () => {
  const scrollToBuySection = () => {
    // Find the target section to scroll to
    const section = document.getElementById('intro');

    // Scroll to the section
    section.scrollIntoView({ behavior: 'smooth' });
  };
  const sectionText = useI18nSection('outMore')


  return (
    <div id="whitepaper" className="text-center" style={{ paddingTop: 100 }}>
      <div>
        <div className="btn-whitepaper">
          <h1 className="setion_title">{sectionText.title}</h1>
          <img src="img/about_line.svg" className=" line justify-content-center mx-auto" style={{ maxWidth: '100%' }}></img>
          <p className="text-secondary-header">{sectionText.description}</p>
          <a type="button" className="btn-primary text-decoration-n" href="./img/shilbaprime/whitepaper.pdf" target="_blank"> {sectionText.link}
            <img style={{ 'marginLeft': '5px' }} src="/img/go-right.svg" />
          </a>
        </div>
        <div className="wrapper">
            <div className="info-cols">
                <div className="col-xs-12 col-md-6  d-flex justify-content-center">
                    <div className="row">
                        <div className="image-item"><img src="/img/shilbaprime/1.png" alt="" /></div>
                    </div>
                </div>
                <div className="col-xs-12 col-md-6">
                    <div className="content">
                        <h1 className="setion_title body-title-header">{sectionText.info1.title}</h1>
                        <p className="text-secondary"> {sectionText.info1.description}</p>
                        <a type="button" className="btn-primary btn-left" onClick={scrollToBuySection} > {sectionText.buyNow} <img src="/img/go-right.svg" /></a>
                    </div>
                </div>
            </div>
            <div className="info-cols">
                <div className="col-xs-12 col-md-6  d-flex justify-content-center">
                    <div className="row">
                        <div className="image-item"><img src="/img/shilbaprime/2.png" alt="" /></div>
                    </div>
                </div>
                <div className="col-xs-12 col-md-6">
                    <div className="content">
                        <h1 className="setion_title body-title-header">{sectionText.info2.title}</h1>
                        <p className="text-secondary"> {sectionText.info2.description} </p>
                        <a type="button" className="btn-primary btn-left" onClick={scrollToBuySection} > {sectionText.buyNow} <img src="/img/go-right.svg" /></a>
                    </div>
                </div>
            </div>
            <div className="info-cols">
                <div className="col-xs-12 col-md-6  d-flex justify-content-center">
                    <div className="row">
                        <div className="image-item"><img src="/img/shilbaprime/3.png" alt="" /></div>
                    </div>
                </div>
                <div className="col-xs-12 col-md-6">
                    <div className="content">
                        <h1 className="setion_title body-title-header">{sectionText.info3.title}</h1>
                        <p className="text-secondary"> {sectionText.info3.description}</p>
                        <a type="button" className="btn-primary btn-left" onClick={scrollToBuySection} > {sectionText.buyNow} <img src="/img/go-right.svg" /></a>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  );
};
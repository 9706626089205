import React from "react";
import {useI18nSection} from "../../utils/languageUtils";


export const Featured = () => {
  const sectionText = useI18nSection('features')

  return (
    <div className="container text-center pd-top-100">
      <h1 className="setion_title text-center">{sectionText.title}</h1>
      <div className="row justify-content-center d-none d-lg-flex block-top">
        <div className="col-sm-3 text-center">
          <img src="img/feature1.svg" alt="Logo 1" className="img-fluid" />
        </div>
        <div className="col-sm-3 text-center">
          <img src="img/feature2.svg" alt="Logo 2" className="img-fluid" />
        </div>
        <div className="col-sm-3 text-center">
          <img src="img/feature3.svg" alt="Logo 3" className="img-fluid" />
        </div>
        <div className="col-sm-3 text-center">
          <img src="img/feature4.svg" alt="Logo 4" className="img-fluid" />
        </div>
      </div>
      <div className="row block-bottom">
        <div className="col-sm-4 text-center">
          <img src="img/feature5.svg" alt="Logo 5" className="img-fluid" />
        </div>
        <div className="col-sm-4 text-center">
          <img src="img/feature6.svg" alt="Logo 6" className="img-fluid" />
        </div>
        <div className="col-sm-4 text-center">
          <img src="img/feature7.svg" alt="Logo 7" className="img-fluid" />
        </div>
      </div>
    </div>
  );
};

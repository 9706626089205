import React, { useState,useEffect, useMemo } from 'react';
import { Navbar, Nav, Container, Row, Col, Card } from 'react-bootstrap';
import {calculateUSDNeeded, calculateTokenOutput , 
  calculateTokensForBNB, calculateBNBNeeded, isValidNumber, truncateMiddle,
  CONST, useWallet} from '../services/wallet-service';
import configs from '../config.main.json';
import { useCountdown } from '../services/utils';
import { useTokenInfo, getUserPurchaseInfo} from '../services/token-service';
import {useI18nSection} from "../utils/languageUtils";

export const Header = () => {
  const currencies = [
    { text: 'ETH', imageSrc: '/img/shilbaprime/icon@eth.svg', curr:CONST.ETH, network:CONST.ETH },
    { text: 'USDT', imageSrc: '/img/shilbaprime/icon@erc20.svg', curr:CONST.ERC20, network:CONST.ETH },
    { text: 'BNB', imageSrc: '/img/shilbaprime/icon@bnb.svg', curr:CONST.BNB, network:CONST.BSC },
    { text: 'USDT', imageSrc: '/img/shilbaprime/icon@bep20.svg', curr:CONST.USDT, network:CONST.BSC },
    // { text: 'SOLANA', imageSrc: '/img/icon@solana.svg', curr:CONST.SOL, network:CONST.SOL },
  ];
  
  const [network, setNetwork] = useState("")
  const [networkPrice, setNetworkPrice] = useState(0);
  const [useTotalBought, setUseTotalBought] = useState();
  
  const [selectedCurr, setSelectedCurr] = useState();
  
  const endSaleTime = useMemo(() => new Date(configs['endSaleTime']).getTime(), []); // Memoize endSaleTime
  const { days, hours, minutes, seconds } = useCountdown(endSaleTime);
  const tokenInfo  = useTokenInfo(configs)
  const wallet=useWallet(network, configs);
  const [tokenInput, setTokenInput] = useState('');


  const [isClicked, setIsClicked] = useState(false);
  const coolDownTime = 300; // milliseconds

  const sectionText = useI18nSection('buyForm')

  useEffect(() => {
    if (isClicked) {
      const timeoutId = setTimeout(() => setIsClicked(false), coolDownTime);
      return () => clearTimeout(timeoutId);
    }
  }, [isClicked]);

  useEffect(() => {
    if(!wallet){
      return;
    }

    const loadPurchaseInfo = async () => {
      try{

        const info =await getUserPurchaseInfo(configs, wallet.currentAddress)
        if(info){
          setUseTotalBought(info)
        }
      }
      catch(err){
        
      }
    }
    loadPurchaseInfo()
  }, [wallet.currentAddress, configs]); // Empty dependency array ensures this effect runs only once
  
  
  
  useEffect(() => {
    // Retrieve the stored value from localStorage
    const currIdxVal = localStorage.getItem('CurrIdx');
    
    // Update the state with the stored value
    if (currIdxVal !== null) {
      const curr  = currencies[currIdxVal]
      setSelectedCurr(curr);
      setNetwork(curr.network)
    }
    else{
      setSelectedCurr(currencies[0]);      
      setNetwork(currencies[0].network)
    }
    
  }, []); // Empty dependency array ensures this effect runs only once
  

  useEffect(() => {
    if(!network) {
      return;
    }
    const fetchData = async () => {
      try {
        const response = await fetch(configs[network]['USDT_Price']); // Assuming 'data.json' is a local file
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        setNetworkPrice(jsonData.price);
      } catch (error) {
        console.error('There was a problem fetching the data:', error);
      }
    };

    fetchData();
  }, [network]);
  

  

  const handleTokenInputChange = (event) => {
    const { value } = event.target;
    if(value ==="") {
        setTokenInput(value);
        setCurrencyInput("")
        return
    }
    if(!tokenInfo || !tokenInfo?.tokenPriceInUsdt){
      return
    }
    // Regular expression to allow only numeric and float values
    if (/^\d*\.?\d*$/.test(value) && isValidNumber(value)) {
      setTokenInput(value);
      if(selectedCurr.curr === CONST.BNB || selectedCurr.curr === CONST.ETH){
        setCurrencyInput(calculateBNBNeeded(value, networkPrice, tokenInfo?.tokenPriceInUsdt))
      }
      else{
        setCurrencyInput(calculateUSDNeeded(value, tokenInfo?.tokenPriceInUsdt))
      }
    }
  };

  const [currencyInput, setCurrencyInput] = useState('');

  const handleCurrencyInputChange = (event) => {
    const { value } = event.target;
    if(value ==="") {
        setTokenInput("");
        setCurrencyInput(value)
        return
    }
    if(!tokenInfo || !tokenInfo?.tokenPriceInUsdt){
      return
    }
    // Regular expression to allow only numeric and float values
    if (/^[0-9]*[.]?[0-9]*$/.test(value) && isValidNumber(value)) {
      setCurrencyInput(value);
      if(selectedCurr.curr === CONST.BNB || selectedCurr.curr === CONST.ETH){
        setTokenInput(calculateTokensForBNB(value, networkPrice, tokenInfo?.tokenPriceInUsdt))
      }
      else{
        setTokenInput(calculateTokenOutput(value, tokenInfo?.tokenPriceInUsdt))
      }
    }
  };

  const handleBuyTokenClick = async () => {
    if (!isClicked) {
      setIsClicked(true);
      // Your button click logic here
    }

    if(selectedCurr.curr === CONST.BNB || selectedCurr.curr === CONST.ETH){
      await wallet.buyTokens(currencyInput)
    }
    else{
      await wallet.buyTokensUSDT(currencyInput);
    }
  }


  const handleBuyMaxClick = async () => {
    if (!isClicked) {
      setIsClicked(true);
      // Your button click logic here
    }

    if(selectedCurr.curr === CONST.BNB || selectedCurr.curr === CONST.ETH){
      const maxVal = await wallet.getMaxAmount()
      await wallet.buyTokens(maxVal)
    }
    else{
      const maxUsdt = await wallet.getMaxUSDT()
      await wallet.buyTokensUSDT(maxUsdt);
    }
  }

  const handleKeyPressCurr= (event) => {
    // Allow the dot character (.) only if it doesn't already exist in the input value
    if (event.key === '.' && currencyInput.includes('.')) {
      event.preventDefault();
    }
  };

  const handleKeyPressToken = (event) => {
    // Allow the dot character (.) only if it doesn't already exist in the input value
    if (event.key === '.' && tokenInput.includes('.')) {
      event.preventDefault();
    }
  };


  const handleSwitchOption = (idx) => {
    const curr = currencies[idx]
    setSelectedCurr(curr)
    setNetwork(curr.network)
    localStorage.setItem('CurrIdx', idx);

    setTokenInput('')
    setCurrencyInput('')

  };

  return (
    <div id="intro" className='intro'>
      {/* <div className="container"> */}
      <div style={{paddingLeft:'12px',paddingRight: '12px', height:'100%', width:'100%', display:'flex'}}>
          <div className="bannerSec">
          <Row style={{flex:1}}>
                <Col md={7} sm={12}>
                  {/* Content for the first column */}
                  {/* This column takes up 7/12 of the screen width on medium devices and above */}
                </Col>
                <Col md={5} sm={12} style={{display:'flex', flexDirection:"row", alignContent:'center'}}>
                  <div className="walletBox" id='walletBox'>
                    <div className="w-100 d-flex flex-column align-items-center justify-content-start text-center  ">
                      <h4 className="text-black text-center font-18 fw-bold">{sectionText.intro}</h4>
                      <div className="d-flex align-items-center justify-content-center w-100 gap-3 fw-regular font-20 counter mb-3  ">
                        <div className="rounded-3 time-card text-center  ">
                          <div id="days" className="value  ">{days}</div>
                          <div className="indicator  ">{sectionText.day}</div>
                          <img className="colon-item" src="./img/colon.svg" />
                        </div>
                        <div className="rounded-3 time-card text-center  ">
                          <div id="hours" className="value  ">{hours}</div>
                          <div className="indicator  ">{sectionText.hrs}</div>
                          <img className="colon-item" src="./img/colon.svg" />
                        </div>
                        <div className="rounded-3 time-card text-center  ">
                          <div id="minutes" className="value  ">{minutes}</div>
                          <div className="indicator  ">{sectionText.mins}</div>
                          <img className="colon-item" src="./img/colon.svg" />
                        </div>
                        <div className="rounded-3 time-card text-center  ">
                          <div id="seconds" className="value  ">{seconds}</div>
                          <div className="indicator  ">{sectionText.sec}</div>
                        </div>
                      </div>
                      <div className="title-wallet">
                        <p className="fw-semibold text-black font-17">{sectionText.funRaised}: {tokenInfo?.totalFundRaise.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            minimumFractionDigits: 2,
                          })} / $12,000,000</p>
                        {wallet.currentAddress && <div className="d-flex justify-content-center align-items-center text-center mb-2 text-secondary fw-semibold fs-7">
                          <p className="text-secondary">{sectionText.wallet} ${truncateMiddle(wallet.currentAddress)}</p>
                          {/* <img className="img-fluid ms-2 cursor-pointer" src="./img/info-icon.svg" /> */}
                        </div>
                        }
                        {!wallet.currentAddress && <div className="d-flex justify-content-center align-items-center text-center mb-2 text-secondary fw-semibold fs-7">
                          <p className="text-secondary">{sectionText.warning} {configs.targetToken.symbol} <a href='https://metamask.io/' target='_blank'>{sectionText.link}</a> </p>
                          {/* <img className="img-fluid ms-2 cursor-pointer" src="./img/info-icon.svg" /> */}
                        </div>
                        }
                        {wallet.currentAddress && <div className="d-flex justify-content-center align-items-center text-center mb-2 text-secondary fw-semibold fs-7">
                          <p className="text-secondary">{sectionText.boughtAmount} ${configs.targetToken.symbol} = {useTotalBought}</p>
                          {/* <img className="img-fluid ms-2 cursor-pointer" src="./img/info-icon.svg" /> */}
                        </div>
                        }
                        {wallet.currentAddress && 
                        <div className="d-flex justify-content-center align-items-center text-center mb-2 text-secondary fw-semibold fs-7">
                          <p className="text-secondary">{sectionText.stakeableAmount} ${configs.targetToken.symbol} = {useTotalBought}</p>
                          {/* <img className="img-fluid ms-2 cursor-pointer" src="./img/info-icon.svg" /> */}
                        </div>
                        }
                        <p className="text-center mb-3 font-14 dashTitle fw-semibold">1 {configs.targetToken.symbol} = ${tokenInfo?.tokenPriceInUsdt}</p>
                      </div>
                    </div>
                    {wallet.currentAddress && 
                      <div className="swapArea">
                          <div className="tab-container gap-2  ">
                            <button onClick={() => handleSwitchOption(0)}  

                              className={`btn btn-wallet btn-light font-14 text-uppercase d-flex align-items-center justify-content-center ${selectedCurr?.curr === CONST.ETH ? 'selected' : ''}`}>
                              <img height="24" alt="" src="./img/shilbaprime/icon@eth.svg" />
                              <span className="px-2 font-14">ETH</span>
                            </button>
                            
                            <button onClick={() => handleSwitchOption(1)}  
                              className={`btn btn-wallet btn-light font-14 text-uppercase d-flex align-items-center justify-content-center ${selectedCurr?.curr === CONST.ERC20 ? 'selected' : ''}`}>
                              <img height="24" alt="" src="./img/shilbaprime/icon@erc20.svg" />
                              <span className="px-2 font-14">USDT</span>
                            </button>
                            <button onClick={() => handleSwitchOption(2)}  

                              className={`btn btn-wallet btn-light font-14 text-uppercase d-flex align-items-center justify-content-center ${selectedCurr?.curr === CONST.BNB ? 'selected' : ''}`}>
                              <img height="24" alt="" src="./img/shilbaprime/icon@bnb.svg" />
                              <span className="px-2 font-14">BNB</span>
                            </button>
                            <button onClick={() => handleSwitchOption(3)}  
                              className={`btn btn-wallet btn-light font-14 text-uppercase d-flex align-items-center justify-content-center ${selectedCurr?.curr === CONST.USDT ? 'selected' : ''}`}>
                              <img src="./img/shilbaprime/icon@bep20.svg" height="24" alt="" />
                              <span className="px-2 font-14">USDT</span>
                            </button>
                          </div>
                          <div className="swapSection mt-3  " style={{ paddingBottom: '8px' }}>
                            <div className="body-section mt-1 ng-star-inserted">
                              <div className="row" style={{ marginTop: '10px' }}>
                                <div className="col-md-6 ps-md-1 mt-3 mt-md-0">
                                  <div className="d-flex align-items-center justify-content-between mb-2">
                                    <label className="d-block family-title font-14 fw-bold text-truncate text-dark w-100"> {sectionText.pay} {selectedCurr?.text}</label>

                                  </div>
                                  <div className="amountField d-flex align-items-start">
                                    <input 
                                      value={currencyInput} 
                                      onChange={handleCurrencyInputChange} 
                                      onKeyPress={handleKeyPressCurr}
                                      type="text"
                                      className="form-control form-control-custom text-truncate ng-valid ng-dirty ng-touched" 
                                      placeholder="0" />
                                    <div className="amountType">
                                      <img src={selectedCurr?.imageSrc} style={{ 'height': '26px' }} />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6 ps-md-1 mt-3 mt-md-0">
                                  <div className="d-flex align-items-center justify-content-between mb-2">
                                    <label className="d-block family-title font-14 fw-bold text-truncate text-dark"> {sectionText.get} {configs.targetToken.symbol} </label>
                                  </div>
                                  <div className="amountField d-flex align-items-start">
                                    <input 
                                      value={tokenInput} 
                                      onChange={handleTokenInputChange} 
                                      type="text" 
                                      onKeyPress={handleKeyPressToken}
                                      className="form-control form-control-custom text-truncate ng-valid ng-dirty ng-touched" 
                                      placeholder="0"
                                    />
                                    <div className="amountType">
                                      <img src="/img/shilbaprime/logo.png" style={{ 'height': '30px' }} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center justify-content-center gap-3 mt-2  ">
                            <button 
                              disabled={isClicked} 
                              type="button" 
                              onClick={handleBuyTokenClick}
                              translate="" className="btn btn-primary w-100">{sectionText.buyNow}</button>
                            <button 
                                disabled={isClicked} 
                                type="button"
                                onClick={handleBuyMaxClick}
                                className="btn btn-primary w-100">{sectionText.buyMax}</button>
                          </div>
                          <div className="mt-3 padding-top-20 ">
                          
                            <p translate="" className="font-12 text-dark text-center m-0 mt-2"> <img src="/img/shilbaprime/logo.png" style={{ 'height': '35px' }} />Powered by <a target="_blank" href='https://web3paymentsolutions.io/' className=" "><img src="./img/shilbaprime/W3P_Black.svg" alt="" className="poweredByIcon" /></a></p>
                          </div>
                        </div>
                    } 
                    </div>
                </Col>
          </Row>
            
            
          </div>
        </div>
      </div>
    // </div>
  );
};

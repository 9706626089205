import React from "react";
import {useI18nSection} from "../../utils/languageUtils";


export const TokenNomics = () => {
  const sectionText = useI18nSection('tokenomics')

  return (
    <div id="tokenomics">
      <div className="container">
        <h1 className="setion_title text-center padding-bottom">{sectionText.heading}</h1>
        <div className="row padding-bottom" >
          <div className="col-12 col-md-5">
            <div className="tokenomics-box">
              <img src="/img/shilbaprime/logo.png" className="img-fluid tokenomics-image" />
              <h3 className="font-18 fw-bold text-dark">{sectionText.title}</h3>
              <p className="font-18 text-secondary px-3 mb-3 text-center text-lg-start">{sectionText.description}</p>
              <ul className="text-start">
                <li className="text-secondary">{sectionText.item1}</li>
                <li className="text-secondary">{sectionText.item2}</li>
                <li className="text-secondary">{sectionText.item3}</li>
                <li className="text-secondary">{sectionText.item4}</li>
                <li className="text-secondary">{sectionText.item5}</li>
              </ul>
              <p className="text-center font-size-18"> {sectionText.address} <a href="https://etherscan.io/token/0xF8251b2b41A1dCCa3d14e3881876A36ddb592ab0" target="_blank"> 0xF8251b2b41A1dCCa3d14e3881876A36ddb592ab0 </a> </p>
            </div>
          </div>
          <div className=" col-12 col-md-5 d-flex justify-content-center">
            <img src="/img/shilbaprime/tokenomics-chart.svg" className="postion-absolute" />
          </div>
          <div className=" col-12 col-md-2 " style={{minHeight: 100}}>
            <div className="chart-label  d-flex flex-wrap flex-row " style={{flexDirection:'row', flexWrap:'wrap'}}>
              <div className="d-flex align-items-center mb-3 bg-yellow">
                <span className="label bg-presale me-lg-3 me-2"></span>
                <div className="chart-label-info">
                  <h4 className="mb-0 text-purple">{sectionText.legend1}</h4>
                  <p className="mb-0 text-dark">25%</p>
                </div>
              </div>
              {/* BLOCK 2 */}
              <div className="d-flex align-items-center mb-3">
                <span className="label bg-purple me-lg-3 me-2"></span>
                <div className="chart-label-info">
                  <h4 className="mb-0 text-purple">{sectionText.legend2}</h4>
                  <p className="mb-0 text-dark">25%</p>
                </div>
              </div>
              {/* BLOCK 3 */}
              <div className="d-flex align-items-center mb-3">
                <span className="label bg-light-blue me-lg-3 me-2"></span>
                <div className="chart-label-info">
                  <h4 className="mb-0 text-purple">{sectionText.legend3}</h4>
                  <p className="mb-0 text-dark">25%</p>
                </div>
              </div>
              {/* BLOCK 4 */}
              <div className="d-flex align-items-center mb-3">
                <span className="label bg-primary me-lg-3 me-2"></span>
                <div className="chart-label-info">
                  <h4 className="mb-0 text-purple">{sectionText.legend4}</h4>
                  <p className="mb-0 text-dark">15%</p>
                </div>
              </div>
              {/* BLOCK 5 */}
              <div className="d-flex align-items-center mb-3">
                <span className="label bg-liq me-lg-3 me-2"></span>
                <div className="chart-label-info">
                  <h4 className="mb-0 text-purple">{sectionText.legend5}</h4>
                  <p className="mb-0 text-dark">10%</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


import React from "react";
import {useI18nSection} from "../../utils/languageUtils";

export const RoadMap = () => {
  const sectionText = useI18nSection('roadMap')

  return (
    <div id="roadmap" className="text-center">
      <div className="container">
      <h1 className="setion_title">{sectionText.title}</h1>
      <img src="img/about_line.svg" className="line justify-content-center mx-auto" style={{maxWidth:'100%'}}></img>
          <section className="timeline">
            <ol>
              <li>
                <div>
                  <time>{sectionText.phase1.title}</time> {sectionText.phase1.description}
                </div>
              </li>
              <li>
                <div>
                <time>{sectionText.phase2.title}</time> {sectionText.phase2.description}
                </div>
              </li>
              <li>
                <div>
                <time>{sectionText.phase3.title}</time> {sectionText.phase3.description}
                </div>
              </li>
              <li>
                <div>
                <time>{sectionText.phase4.title}</time> {sectionText.phase4.description}
                </div>
              </li>
              <li>
                <div>
                <time>{sectionText.phase5.title}</time> {sectionText.phase5.description}
                </div>
              </li>
              <li>
                <div>
                <time>{sectionText.phase6.title}</time> {sectionText.phase6.description}
                </div>
              </li>
              <li></li>
            </ol>
          </section>
      </div>
    </div>
  );
};
